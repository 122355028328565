
























































































































































import Vue from 'vue';

export default Vue.extend({
  name: 'create-account',

  data: () => ({
    rememberMe: false,
    acceptTerms: false,
    valid: false,
    showPassword: false,
    showConfirmPassword: false,
    validPassword: '',
    user: {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      cpfCnpj: '', // TODO: add mask to cpf or cnpj
      address: '',
      contact: '',
    },
    loading: false,
    message: '',
  }),
  computed: {
    emailRules() {
      return [(v) => !!v || this.$t('dashboard.invalid-email')];
    },
    nameRules() {
      return [(v) => !!v || this.$t('dashboard.invalid-name')];
    },
    passwordRules() {
      return [
        (str) => !!str || this.$t('dashboard.invalid-password'),
        (str) => str.length >= 7 || this.$t('dashboard.invalid-password-lenght'),
        (str) => {
          const constructedRegEx =
            '^(?=(?:.*[0-9]){1})(?=(?:.*[a-z]){1})(?=(?:.*[A-Z]){1})(?=(?:.*[[!@#$%^&*()_+]){1}).+$';
          const PasswordRegEx = new RegExp(constructedRegEx, 'm');
          return PasswordRegEx.test(str) || this.$t('dashboard.invalid-password-format');
        },
      ];
    },
    termsRules() {
      return [(v) => !!v || this.$t('dashboard.invalid-accept-terms')];
    },
  },
  methods: {
    async send() {
      if (this.valid) {
        this.loading = true;
        try {
          await this.$store.dispatch('auth/createAccount', this.user);
          this.loading = false;
          this.$router.push('create-account-confirm');
        } catch (error) {
          console.log('error in CreateAccount - send');
          console.log(error);
          this.message = error.message;
          this.loading = false;
        }
      }
    },
    validaConfirmPassword(event: any) {
      if (event.target.value !== this.user.password) {
        this.validPassword = this.$t('dashboard.invalid-password-match').toString();
      } else {
        this.validPassword = '';
      }
    },
    back() {
      this.$router.push('/');
    },
  },
});
